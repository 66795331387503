import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AssurantLogo from "../../common-icon/assurant-logo.svg";

export default function TermConditions(props) {
  return (
    <div className="term-conditions">
      <Dialog
        open={props.show}
        onClose={props.showTermConditions}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ alignItems: "center" }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent
          className="term-conditions-dailog"
          style={{ width: "450px"}}
        >
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <div>
              <div className="login-title">
                <img
                  className="assurant-logo logo-style"
                  src={AssurantLogo}
                  alt={"logo"}
                />
                <p className="digital-hub-name">Quick Quote</p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    fontSize: "18px",
                    margin:'10px',
                  }}
                >
                  Terms &#38; Conditions
                </p>
              </div>
          <div style={{margin:'10px', height:'370px',fontSize:'14px', overflow:'auto'}}>
              <p><b>EFFECTIVE:</b> October 29, 2021</p>
              <b>
                IMPORTANT, READ CAREFULLY: YOUR USE OF AND ACCESS TO THE WEB
                SITE AND PRODUCTS AND SERVICES AND ASSOCIATED SOFTWARE
                (COLLECTIVELY, THE "SERVICES") OF COMPANY (AS DEFINED BELOW), IS
                CONDITIONED UPON YOUR COMPLIANCE WITH AND ACCEPTANCE OF THESE
                TERMS, WHICH INCLUDE YOUR AGREEMENT TO ARBITRATE CLAIMS AND TO
                WAIVE CLASS ACTIONS. PLEASE REVIEW THOROUGHLY BEFORE USING THE
                SITE.
              </b>
              <p>
                <b>Use of Site</b>
              </p>
              <p>
                The entities listed below (collectively, “Company”, “We”, “Us”,
                or “Our”) are the providers of the Services through use of this
                Disaster Recovery Web Site (“Site”). Such Services include, but
                are not limited to, viewing and accessing reports and data, and
                certain other functionality related to systems outages. Company
                requires that each visitor (“You”, “Your”, or "User") to this
                Site adhere to these Terms of Use. Please read these Terms of
                Use carefully before using the Site. By accessing this Site, You
                acknowledge that You have read, understand and accept each of
                the Terms of Use. You are bound by any revisions to these Terms
                of Use and should visit this page at each visit to review the
                current Terms of Use. If You do not agree to all of these Terms
                of Use, You should not use this Site.
              </p>
              <b>Company Defined</b>
              <p>
                Company means the following companies and their affiliates:</p>

                <p>American Bankers Insurance Company of Florida American Security
                Insurance Company Assurant Service Protection, Inc. Automotive
                Warranty Services, Inc. Automotive Warranty Services of Florida,
                Inc. Consumer Program Administrators, Inc. Federal Warranty
                Service Corporation First Extended Service Corporation Motor
                Warranty Services of North America National Product Care Company
                United Service Protection Corporation United Service Protection,
                Inc. Virginia Surety Company, Inc.
              </p>

              <b>Scope of Site</b>
              <p>
                To use the Site, You must have an electronic device with access
                to the internet that is compatible with the Site. Company does
                not warrant that the Site will be compatible with Your
                electronic device. Some features are only available if You have
                created an online account with Company.
              </p>

              <b>Access to Password Protected or Secure Areas</b>
              <p>
                Access to and use of password protected or secure areas of this
                Site are restricted to authorized persons only. Any User who
                accesses or attempts to access such areas without authorization
                may be subject to prosecution.
              </p>
              <b>No Warranty</b>
              <p>
                Company does not warrant the accuracy or completeness of the
                information, text, graphics, links or other content contained on
                this Site or any other server. Company makes no representation
                concerning the suitability of this documentation for any
                purpose. It is provided "as is" without express or implied
                warranty.</p>
                
                <b> Third-Party Sites, Products, and Services</b>

                <p> This Site may contain hyperlinks to Web sites operated by parties other
                than Company and its subsidiaries solely as a convenience to
                Users. In the course of providing services related to the Site,
                We may also make recommendations with respect to third-party
                services or resources that are commonly used or popular. Company
                does not control such Web sites and is not responsible for the
                content. The inclusion of hyperlinks to such Web sites does not
                imply any endorsement, approval or certification by Company of
                the material on such Web sites or any association with their
                operators. Viewing a linked site, accessing and using the
                information, materials, products or utilizing outside services
                and resources is done entirely at Your own risk. Accordingly, We
                encourage You to be aware when You leave the Site or use a
                recommended resource or service and to read the terms and
                conditions and privacy policy of each such third-party site,
                service, or resource that You visit or use. Furthermore, We make
                no express or implied warranties with regard to the information,
                material, products or services that are contained on or
                accessible through linked sites.
              </p>
              <b>Limitation of Liability</b>
              <p>
                Company expressly disclaims all warranties, including, but not
                limited to, the implied warranties of merchantability, fitness
                for a particular purpose, title, non-infringement, security and
                accuracy. Information in this Site may contain inaccuracies or
                errors. Information on this Site may be changed or updated
                without notice.Company has no obligation to update information
                on this Site, so such information may be out of date at any
                given time. Company also may make improvements or changes in
                products or content described on this Site at any time without
                notice.</p>
                <p> Company will not be responsible for any damages or
                injuries that accompany or result from any use of this Site.
                Company specifically disclaims any liability (whether based in
                contract, tort, strict liability or otherwise) for any direct,
                indirect, incidental, consequential, or special damage arising
                out of or in any way connected with access to or use of this
                Site (even if Company has been advised of the possibility of any
                such damage), including any liability associated with any virus
                that may infect a User's computer equipment. Under no
                circumstances will Company be liable for any loss or damage
                caused by a User's reliance on information obtained through this
                Site. It is the sole responsibility of each User to evaluate the
                accuracy, completeness or usefulness of any information,
                opinion, advice or other content available through this Site.</p>
                
                <b> No Representation as to Suitability </b>
               
               <p> Company makes no representation
                as to the financial or other suitability of any product or
                service described on this Site for any User.
              </p>

              <b>Proprietary Rights</b>
              <p>
                The design, content, graphics, compilation, information,
                organization, products, content, and all other elements of the
                Site provided by Company are protected under applicable
                copyrights, trademarks, trade dress, and other proprietary
                rights, including without limitation, intellectual property
                rights. You do not acquire ownership rights to the Service, Site
                materials, or any content included therein. All Site materials
                are the property of Company or Our affiliated companies and/or
                third-party licensors. You agree not to sell, license, copy,
                modify, alter, reverse engineer, disassemble, decompile,
                translate, edit, adapt, create derivative works from, or
                otherwise make unauthorized use of the materials. You agree not
                to disclose, publicly perform or display, transmit, publish,
                distribute or provide the materials to any other party.</p> 
                <p>
                Except as expressly permitted in these Terms of Use, You may not: (i)
                copy in whole or in part, modify or create derivative works
                based on the Site or Site materials; (ii) remove any proprietary
                notices or labels on the Site or Site materials; (iii) use the
                Site or Site materials in any manner or for any purpose that
                infringes, misappropriates or otherwise violates any
                intellectual property right or other right of any person or
                party, or that violates any applicable law; or (iv) use the Site
                or Site materials for any commercial or illegal purpose, or for
                any purpose not expressly permitted by these Terms of Use.
               
                Company reserves all rights not expressly granted in these Terms
                of Use. 
                
                Company reserves the right to exercise whatever lawful
                means it deems necessary to prevent unauthorized or prohibited
                uses.
              </p>
              <b>System Requirements.</b> 
              <p>Use of the Services requires one or
              more compatible devices, Internet access (fees may apply), and
              certain software (fees may apply), and may require obtaining
              updates or upgrades from time to time. Because use of the Services
              involves hardware, software, and Internet access, Your ability to
              access and use the Services may be affected by the performance of
              these factors. High speed Internet access is recommended. You
              acknowledge and agree that such system requirements, which may be
              changed from time to time, are Your responsibility.</p>
              
              <b>Privacy</b>
              <p>
                As part of the normal operation of Our Site, We may collect a
                limited amount of information about Your visit. For Our privacy
                policies, please refer to 
                 <a href={"https://www.assurant.com/privacypolicy"} rel="noreferrer" target="_blank"> https://www.assurant.com/privacypolicy </a>
              </p>
              <b>Indemnification.</b>
              <p>You agree to indemnify, defend, and hold Company, its affiliates,
              partners, agents, officers, directors, employees, subcontractors,
              successors, assigns, and third party suppliers of information
              harmless from any claims, losses, damages, liabilities, including
              legal fees and expenses, arising out of Your misuse of the Site or
              Site materials or any violation by You of these Terms of Use.
              Company reserves the right, at Your expense, to assume the
              exclusive defense and control of any matter for which You are
              required to indemnify Company, and You agree to cooperate with
              Company’s defense of these claims. Company will use reasonable
              efforts to notify You of any such claim, action, or proceeding
              upon becoming aware of it.</p>

              <b>Usage Rules and Prohibited Conduct</b>
              <p><b>
                YOU UNDERSTAND AND HEREBY ACKNOWLEDGE AND AGREE THAT YOU MAY NOT
                AND WARRANT THAT YOU WILL NOT:
              </b></p>
              <p>
                1. use the Site or any location information displayed within the
                Site to stalk, harass, abuse, defame, threaten or defraud other
                users, or collect, attempt to collect, or store location or
                personal information about others;</p>
                <p> 2. use the Site if You are under the age of 13 years old;</p>
                <p> 3. use the Site for any commercial or non-private use without Company’s written consent,
                it being understood that the Site is intended for individual,
                non-commercial use only;</p>
                 <p>4. use the Site for any illegal purpose, or in violation of any local, state national or
                international law, including, without limitation, laws governing
                intellectual property and other proprietary rights, data
                protection and privacy, and import or export control;</p>
                <p> 5. post,store, send, transmit, or disseminate any information or
                material which a reasonable person could deem to be
                objectionable, libelous, offensive, indecent, pornographic,
                harassing, threatening, embarrassing, distressing, vulgar,
                hateful, racially or ethnically offensive, or otherwise
                inappropriate, regardless of whether this material or its
                dissemination is unlawful; </p>
                <p>6. post, store, send, transmit, or disseminate any information or material which infringes any
                patents, trademarks, trade secrets, copyrights, or any other
                proprietary or intellectual property rights;</p>
                <p> 7. use the Site with any products, systems, or applications installed or
                otherwise connected to or in communication with vehicles, or
                otherwise capable of vehicle navigation, positioning, dispatch,
                real time route guidance, fleet management, or similar
                applications;</p>
                <p> 8. use the Site in connection with hazardous environments requiring fail- safe performance or any application
                in which the failure or inaccuracy of that application or the
                Site could lead directly to death, personal injury, or severe
                physical or property damage;</p> 
                <p>9. make unsolicited offers,advertisements, proposals, or send junk mail, to other users of
                the Site. This includes, but is not limited to, unsolicited
                advertising, promotional materials or other solicitation
                material, bulk mailing of commercial advertising, chain mail,
                informational announcements, charity requests, and petitions for
                signatures;</p>
                <p> 10. impersonate any person or entity, falsely claim
                an affiliation with any person or entity, or access the Services
                accounts of other users;</p>
                 <p>11. share passwords or access to Your account while the Site is running and/or accessible with any
                third party or encourage any other user to do so;</p>
                <p> 12.misrepresent the source, identity or content of information
                transmitted via the Site;</p> 
                <p>13. remove, circumvent, disable,damage, reverse engineer, or otherwise interfere with
                security-related features of the Site, digital rights management
                technologies that are integrated in the Site, features that
                prevent or restrict use or copying of any content accessible
                through the Site, or features that enforce limitations on use of
                the Site;</p>
                <p> 14. intentionally interfere with or damage operation
                of the Site or any user's enjoyment of the Site, by any means,
                including uploading or otherwise disseminating viruses, worms,
                or other malicious code;</p>
                <p> 15. attempt to gain unauthorized access to the Site, or any part of it, other accounts, computer systems
                or networks connected to the Site, or any part of it, through
                hacking, password mining or any other means or interfere or
                attempt to interfere with the proper working of the Site or any
                activities conducted on the Site;</p> 
                <p>16. use any robot, spider,scraper or other automated means to access the Site for any
                purpose without Company’s express written permission or bypass
                Company’s robot exclusion headers or other measures Company may
                use to prevent or restrict access to the Site or modify the Site
                in any manner or form, nor to use modified versions of the Site,
                including without limitation for the purpose of obtaining
                unauthorized access to the Site; or</p>
                <p> 17. sell or transfer or allow another person to access Your account password, profile,
                or account.
              </p>
              <b>Laws and Regulations</b>
              
              <p>Access to and use of this Site are subject to all applicable
              federal, state and local laws and regulations.</p>
              
              <b> Governing Law</b>
              <p>
               THESE TERMS OF USE SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH
              THE LAWS OF THE STATE OF GEORGIA WITHOUT REGARD TO CONFLICT OF LAW
              RULES OR PRINCIPLES THAT WOULD CAUSE THE APPLICATION OF LAWS OF
              ANY OTHER JURISDICTION.</p>
              <b>  
              Arbitration </b>
              
              <p>
              If any dispute shall arise between Company and User(s), with reference to the interpretation
              of these Terms of Use or use of the Site, and cannot be settled
              through informal means, the dispute shall be settled by
              arbitration in accordance with the rules of the American
              Arbitration Association. The dispute shall be referred to three
              (3) arbitrators. One arbitrator shall be chosen by each party and
              the two chosen shall promptly select a third arbitrator. If either
              party refuses or neglects to appoint an arbitrator within thirty
              (30) days after the receipt of written notice from the other party
              requesting arbitration and naming its arbitrators, the requesting
              party may name an arbitrator for the other party. Each party shall
              submit its case to the three (3) arbitrators within thirty (30)
              days of the appointment of the third arbitrator unless such time
              is extended by the arbitrators or a majority of them or by
              agreement between the parties. The decision of a majority of the
              arbitrators shall be final and binding on both Company and
              User(s). Company and User(s) shall each bear the expense of its
              own arbitrator, or one-half of the expense of two (2) arbitrators
              if both are appointed by the requesting party as provided above,
              and shall jointly bear and equally bear with the other the expense
              of the third arbitrator and of the arbitration. Any such
              arbitration shall take place in Atlanta, Georgia. This Section
              shall survive termination of these Terms of Use.</p>
              
              <p><u> You and We</u> agree that except as may be required by law, neither You nor We may
              disclose the existence, content, substance, documents, or
              information submitted thereunder, or any results of any
              arbitration hereunder without the prior written consent of other
              party.</p>
                  
             <b> Class Action Waiver</b> 
             
             <p>User(s) shall not be entitled to join
              or consolidate claims under or relating to these Terms of Use by
              or against other individuals or entities or litigate or pursue any
              claim as a representative ‎member of a class or in a private
              attorney general capacity. THIS MEANS THAT USER(S) WAIVE THE RIGHT
              TO INITIATE OR PARTICIPATE IN ANY CLASS OR CONSOLIDATED ‎ACTION
              WHATSOEVER. A court may sever any portion of this dispute
              resolution provision if ‎it finds such unenforceable.</p>
              
              <b> Suspension, Violations and Termination of Service</b>‎
              
              <p> Company reserves the right to disable, suspend or terminate access to the Site for any reason
              and without any notice, unless otherwise prohibited by law.</p>

              <b>Violations of Terms of Use</b>
              <p>Company reserves the right to seek any remedy available at law or in equity for any violation of these
              Terms of Use, including the right to block access from a
              particular Internet address to this Site.</p>
              
              <b> Severability, Waiver, and Entire Agreement</b>
              <p>If any provision of these Terms of Use is invalid, unlawful, void, or for any reason unenforceable, then
              that provision shall be deemed severable from these Terms of Use
              and shall not affect the rest of the Terms of Use and shall
              continue to be valid and enforceable. Company’s failure to enforce
              any part of these Terms of Use shall not constitute a waiver of
              Company’s right to later enforce that or any other part of these
              Terms of Use. Waiver of compliance in any particular instance does
              not mean that Company will waive compliance in the future. In
              order for any waiver of compliance with these Terms of Use to be
              binding, Company must provide User(s) with written notice of such
              waiver through a Company authorized representative.</p>
              <p> These Terms of Use state the entire agreement with regard to the Site and
              supersedes all prior or contemporaneous communications and
              proposals (whether oral, written or electronic) between Company
              and User(s) with respect to the Site. Company shall not be liable
              for any failure to perform Our obligations hereunder where such
              failure results from any cause beyond Our reasonable control
              including, without limitation, mechanical, electronic or
              communications failure or degradation. </p>
              
              <p>These Terms of Use are personal to You as a User, and are not assignable, transferable or
              sublicensable by You except with Company’s prior written consent.
              Company may assign, transfer or delegate any of Our rights and
              obligations hereunder without consent. BY USING THIS SITE, YOU
              AGREE TO THE PROVISIONS CONTAINED ABOVE.</p>

              <p>The Assurant brand mark is a service mark of Assurant. No part of this site may be
              reproduced without written permission from Assurant. </p>
              
              <p>© 2021 Assurant</p>
            </div>
          </div>
          <div>
            <p class="click-cursor"
              style={{
                fontSize: "16px",
                color: "#0066CC",
                float: "right",
                paddingBottom: "20px",
              }}
              onClick={props.showTermConditions}
            >
              CLOSE
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
