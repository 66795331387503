import React from "react";
import AssurantFooterLogo from "./common-icon/assurant-logo-footer-bw.svg";
import SSLFooterLogo from "./common-icon/ssl-secure-footer.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import TermConditions from "./pages/term-conditions/term-conditions";
import { isLogin } from './services/auth';

const FooterPage = (props) => {
  const [show, setModel] = React.useState(false);
  const history = useHistory();
  let location = useLocation();
  const redirectToAdmin = () => {
    history.push("/admin");
  };

  const showTermConditions = () => {
    setModel(!show);
  };


  const isLoginPage = !isLogin() && (location.pathname === '/login');

  return (
    <>
      {!isLoginPage ? (
        <React.Fragment>
          <div className="footer">
            <div class="FooterContent">
              <span>
                <img src={AssurantFooterLogo} alt="assurant-footer-logo" />
              </span>
              <span className="left-logo">
                <img src={SSLFooterLogo} alt="ssl-footer-logo" />
              </span>
            </div>
            <div class="FooterContent">
              <span className="copy-rights">
                © 2021 Assurant, Inc. All rights reserved
              </span>
              {!isLogin() ? (
                <span className="need-help padding-left">
                  <Link onClick={() => redirectToAdmin()}>Admin</Link>
                </span>
              ) : null}
              <span
                className="need-help click-cursor"
                onClick={showTermConditions}
              >
                Terms and Conditions
              </span>
              <span className="need-help">
                {" "}
                <a
                  href={"https://www.assurant.com/privacypolicy"}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy{" "}
                </a>
              </span>
              <span className="need-help">
                <a href={"mailto:csauto@assurant.com"}> Contact Us </a>
              </span>
            </div>
          </div>
          <TermConditions show={show} showTermConditions={showTermConditions} />
        </React.Fragment>
      ) : null}
    </>
  );
};

export default FooterPage;
