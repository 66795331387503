import api from "../apiFactory";

class NetworkService {
  static async getData(url, params) {
    try {
      return api.get(url, params);
    } catch (ex) {
      throw ex;
    }
  }

  static async postData(url, body) {
    try {
      return api.post(url, body);
    } catch (ex) {
      throw ex;
    }
  }

  static async updateData(url, data) {
    try {
      return api.put(url, data);
    } catch (ex) {
      throw ex;
    }
  }


  static async removeData(url, data) {
    try {
      return api.delete(url, data);
    } catch (ex) {
      throw ex;
    }
  }

  
}

export default NetworkService;
