import LocalStorageService from "./storage";

export const isLogin = () => {
  if (LocalStorageService.get("okta-token-storage")) {
    return true;
  }
  return false;
};
export const checkRole = (role="Admin") => {
  //RatingDR AppRole_Admin / GL-Okta-AutoHub-AppRole-RatingDR_Admin-Dev
  const groups = LocalStorageService.get("groups") || [];
  return groups.indexOf(role) > -1;
};

export const shouldAllowAdminAccess = () => {
  if(checkAllExternalRoles()) {
    return checkRole("Admin");
  }
  return true;
}

export const checkAllExternalRoles = () => {
  return checkRole("AutoHub AppRole_User_External") || checkRole("Global Auto External Users");
}