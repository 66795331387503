import { Suspense } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import './styles.css';
import '../pages/admin-view/admin-view.css';
import '../pages/admin-view/event-view.css';
import '../pages/admin-view/list-view.css';
import '../pages/offline-rating/offline-rating.css';
import '../pages/offline-rating/offline-table.css';
import '../pages/offline-rating/rating-card.css';
import '../pages/offline-rating/vehicle-detail.css';
import '../pages/offline-rating/vehicle-listing.css';
import '../pages/outage/maintance-underway/system-grey.css';
import '../pages/outage/system-green/system-green.css';
import '../pages/outage/system-red/system-red.css';
import '../pages/outage/impact-full.css';
import '../pages/outage/tile.css';
import '../pages/page-not-found/page-not-found.css';
import '../components/login/login-okta.css';
import '../components/login/login.css';
import '../components/common/common.css';

const withSuspence = (WrappedComponent) => {
    return (props) => {
       
        return(
            <Suspense fallback= {<section className="suspenseLoader"><CircularProgress className=""/></section>}>
                <WrappedComponent {...props} />
            </Suspense>
        );
    } 
}
export { withSuspence };