import { lazy } from 'react';
import { withSuspence } from '../hocs/withSuspense';
const Outage = lazy(() => import("../pages/outage/outage"));
const OfflineRating = lazy(() => import("../pages/offline-rating/offline-rating"));
const AdminPage = lazy(() => import("../pages/admin-view/admin-view"));
const PageNotFound = lazy(() => import("../pages/page-not-found/page-not-found"));
const LoginPage = lazy(() => import("../components/login/login-okta"));

export const pathname = {
    outage : '/',
    login : '/login',
    admin : '/admin',
    offlineRating : '/offline-rating',
    notfound: '*'
}
export const routeConfig = [
    {
        path: pathname.outage,
        component: withSuspence(Outage)
    },
    {
        path: pathname.login,
        component: withSuspence(LoginPage)
    },
    {
        path: pathname.admin,
        component: withSuspence(AdminPage),
        redirect:"admin",
        isPrivate: true,
    },
    {
        path: pathname.offlineRating,
        component: withSuspence(OfflineRating),
        redirect:"offline-rating",
        isPrivate: true,
    },
    {
        path: pathname.notfound,
        component: withSuspence(PageNotFound)
    }
];