import React from 'react';
import { routeConfig } from './config';
import PrivateRoute from './privateRoute';
import { Route, Switch } from "react-router-dom";


const CustomRoute = (props) => {
  return (
    <Switch>
      {
        routeConfig.map(({ isPrivate, ...restRouteProps}, index) => {
          if(isPrivate){
            return <PrivateRoute exact  key={'route'+index} {...restRouteProps} {...props}/>
          } else{
            return <Route exact key={'route'+index} {...restRouteProps} {...props}/>
          }   
        })
      }
    </Switch>
  );
};
export default CustomRoute;