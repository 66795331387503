import axios from 'axios';
import config   from   "./config";
import { LocalStorageService, NavigationService } from './services';
import uuid from 'react-uuid'

const apiFactory = axios.create({
    baseURL: config.baseurl,
    timeout: 60000, 
});
const redirectOnBasisOfPathName = (pathname) => {
    if(pathname === '/'){
        NavigationService.updateLocation('/login');
    } else{
        NavigationService.updateLocation(pathname);
    }
}
const acessTokenVavlue = () => {
    const oktaTokenStorage =  LocalStorageService.get('okta-token-storage');
    const { pathname } = NavigationService.getLocation();
    if(oktaTokenStorage) {
        let accessToken = JSON.parse(oktaTokenStorage);
        let value;
        if(accessToken && accessToken.accessToken) {
            if(accessToken.accessToken.value) {
                value = accessToken.accessToken.value
            }else if(accessToken.accessToken) {
                value = accessToken.accessToken.accessToken;
            }
        } else {
            LocalStorageService.remove('okta-token-storage');
            redirectOnBasisOfPathName(pathname);
            return;
        }
        return `Bearer ${value ||value.accessToken}`
    }
    return null;
}

const requestHandler = request => {
    request.headers.Authorization = acessTokenVavlue();
    request.headers.CorrelationId = uuid();
    return request;
};

const responseHandler = response => {
    const { pathname } = NavigationService.getLocation();
    if (response.status === 401) {
        LocalStorageService.remove('okta-token-storage');
        redirectOnBasisOfPathName(pathname);
    }
    return response;
};

const errorHandler = error => {
    const { pathname } = NavigationService.getLocation();
    if(error.response.status ===  401) {
        LocalStorageService.remove('okta-token-storage');
        redirectOnBasisOfPathName(pathname);
    }
    return Promise.reject(error);
};

apiFactory.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

apiFactory.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


export default apiFactory;
