import "./App.css";
import HeaderPage from "./HeaderPage";
import FooterPage from "./FooterPage";
import { BrowserRouter as Router } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from '@okta/okta-react';
import React from "react";
import { useHistory } from "react-router-dom";
import config  from "./config-okta";
import { NavigationService } from './services';
import { Route } from './routes';

const App = () => {
  const history = useHistory();
  const { origin } = NavigationService.getLocation();

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    if(history && originalUri) {
      history.replace(toRelativeUrl(originalUri || "/", origin));
    }
  };

  const customAuthHandler = () => {
    if(history) history.push("/login");
  };

  return (
    <div className="App">
      <Security
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
        oktaAuth={new OktaAuth({
          "pkce":config.pkce,
          "clientId":config.clientId,
          "redirectUri":config.redirectUri,
          "issuer": config.issuer,
          "scopes": config.scopes
       })}
      >
        <Router>
          <HeaderPage/>
          <Route />
          <FooterPage/>
        </Router>
      </Security>
    </div>
  );
}

export default App;
