import React from "react";
import { Route, Redirect } from "react-router-dom";
import LocalStorageService from "../services/storage";
import { isLogin, checkRole, shouldAllowAdminAccess } from "../services/auth";
import { pathname } from './config';

const PrivateRoute = ({ component: Component, redirect, ...rest }) => {

  if(redirect) LocalStorageService.set('lastPage', redirect);
  const isAuth = () => {
    if(rest.path === pathname.offlineRating){
      return isLogin();
    }

    if(rest.path === pathname.admin) {
      return isLogin() && shouldAllowAdminAccess();
    }
    
    return checkRole('Admin');
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
