class LocalStorageService {

    static get(key){
        return localStorage.getItem(key)
    }

    static set(key,data){
        localStorage.setItem(key,data)
    }

    static remove(key){
        localStorage.removeItem(key)
    }

    static clearAll(){
        localStorage.clear()
    }
}

export default LocalStorageService;