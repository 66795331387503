const config = {};
export default config;

function load() {
    const env = process.env.REACT_APP_STAGE;
    let filename = 'development.json';
    if(env === 'production'){
        filename = 'production.json';
    }else if(env === 'model'){
        filename = 'model.json';
    }else if( env === 'sandbox') {
        filename = 'sandbox.json';
    }

    return fetch(filename)
    .then(result => result.json())
    .then((newconfig) => {
        for(let prop in config) {
            delete config[prop]
        }
        for(let prop in newconfig) {
            config[prop] = newconfig[prop]
        }
        return config;
    });
}
export {load}