class NavigationService {

    static updateLocation(location){
        if(location === '/'){
            window.location = '/login';
        } else{
            window.location.reload();
        }
    }
    static getLocation(){
        return window.location.pathname;
    }
}
export default NavigationService;