import React from "react";
import Button from "@material-ui/core/Button";
import LogoutIcon from "./common-icon/logout.svg";
import logo from "./logo_color.svg";
import admin from "./common-icon/admin.svg";
import admin_grey from "./common-icon/admin_grey.svg";
import user from "./common-icon/user.svg";
import { useHistory, useLocation } from "react-router-dom";
import user_white from "./common-icon/user_white.svg";
import { useOktaAuth } from "@okta/okta-react";
import LocalStorageService from "./services/storage";
import { checkRole, isLogin } from "./services/auth";
import { Tooltip } from "@material-ui/core";

const HeaderPage = (props) => {
  const history = useHistory();
  let location = useLocation();
  const [showMessage, SetMessage] = React.useState(false);

  const { authState, oktaAuth } = useOktaAuth();

  let adminClass = "admin-btn";
  let userClass = "user-button";
  let userBtnClass;
  let adminBtnClass;
  let userbutton;
  let adminImg, userImg;
  if (location.pathname == "/admin") {
    adminClass += " blackBG";
    adminBtnClass += " user-button";
    adminImg = admin;
    userImg = user;
  } else {
    userClass += " blackBG";
    userBtnClass += " blackColor";
    adminImg = admin_grey;
    userImg = user_white;
  }

  const redirectToAdmin = () => {
    history.push("/admin"); //todo - router way of redirection without hard reload
  };

  const redirectToUser = () => {
    history.push("/");
  };

  const handleClose = () => {
    SetMessage(false);
  };

  const logout = () => {
    LocalStorageService.clearAll();
    oktaAuth.signOut();
    SetMessage(true);
    history.push("/");
  };

  const showAdminRoute = location.pathname !== "/offline-rating";

  const isLoginPage = !isLogin() && location.pathname === "/login";

  return (
    <>
      {!isLoginPage ? (
        <header className="App-header">
          <div className="header-main">
            <span className="assurant">
              <img
                className="logo-image"
                onClick={() => history.push("/")}
                src={logo}
                alt="logo"
              />
            </span>
            {/* <span className="global-automotive">Global Automotive</span> */}

            {isLogin() ? (
              <>
                <span className="admin-button">
                  <Tooltip
                    title="Logout"
                    placement="left"
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <img
                      className="logout-icon"
                      src={LogoutIcon}
                      onClick={logout}
                      alt="logout-icon"
                    />
                  </Tooltip>
                </span>
                {checkRole('Admin') && showAdminRoute ? (
                  <span className="user-button">
                    <Button className={adminClass} onClick={redirectToAdmin}>
                      <img className="admin-image" src={adminImg} alt="img" />
                      admin
                    </Button>
                  </span>
                ) : null}
                {/* <span className="user-button">
              <Button className={userClass} onClick={redirectToUser}>
                <img className="admin-image" src={userImg} alt="img" />
                User
              </Button>
            </span> */}
              </>
            ) : null}
          </div>
        </header>
      ) : null}
    </>
  );
};

export default HeaderPage;
